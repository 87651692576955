import React from 'react'
import "./App.css"
import filmzy from './assets/filmzy.png'
import jam from './assets/Capture.PNG'
import zoo from './assets/zoo.PNG'
import bv from './assets/bv.PNG'
import lp from './assets/lp.PNG'
import sh from './assets/sh.PNG'
import cv from "./assets/Deblina_CV_updated.pdf"


/***********************
  Menu Component
 ***********************/

  const Menu = props => {
    return (
      <div className={`menu-container ${props.showMenu}`}>
        <div className="overlay" />
        <div className="menu-items">
          <ul>
            <li>
              <a href="#welcome-section" onClick={props.toggleMenu}>
                HOME
              </a>
            </li>
            <li>
              <a href="#about" onClick={props.toggleMenu}>
                ABOUT
              </a>
            </li>
            <li>
              <a href="#projects" onClick={props.toggleMenu}>
                PORTFOLIO
              </a>
            </li>
            <li>
              <a href="#contact" onClick={props.toggleMenu}>
                CONTACT
              </a>
            </li>
          </ul>
          <SocialLinks />
        </div>
      </div>
    );
  };
  
  
  /***********************
    Nav Component
   ***********************/
  
  const Nav = props => {
    return (
      <React.Fragment>
        <nav id="navbar">
          <div className="nav-wrapper">
            <p className="brand">
              Deblina
              <strong>M</strong>
            </p>
            <a
              onClick={props.toggleMenu}
              className={props.showMenu === 'active' ? 'menu-button active' : 'menu-button'}
            >
              <span />
            </a>
          </div>
        </nav>
      </React.Fragment>
    );
  };
  
  
  
  /***********************
    Header Component
   ***********************/
  
  const Header = props => {
    return (
      <header id="welcome-section">
        <div className="forest" />
        <div className="silhouette" />
        <div className="moon" />
        <div className="container">
          <h1>
            <span className="line">I am a</span>
            <span className="line">Fullstack developer</span>
            <span className="line">
              <span className="color">&</span> UX-designer.
            </span>
          </h1>
          <div className="buttons">
            <a href="#projects">my portfolio</a>
            <a href="#contact" className="cta">
              get in touch
            </a>
          </div>
        </div>
      </header>
    );
  };
  
  
  /***********************
    About Component
   ***********************/
  
  const About = props => {
    return (
      <section id="about">
        <div className="wrapper">
          <article>
            <div className="title">
              <h3>Who am I?</h3>
              <p className="separator" />
            </div>
            <div className="desc full">
              <h4 className="subtitle">My name is Deblina.</h4>
              <p>
                I am a full-stack developer and UX designer based in Stockholm, Sweden.
            
              </p>
              <p>
                
                I have a passion for bringing ideas to life and creating content to simplify user experience.
                I really enjoy solving problems as well as making things pretty and easy to use. 
                I can't stop learning new things. I thrive in environment that constantly embraces new technologies. I also love reading and watchin movies. I have this incredible ability to be at one place for hours together without moving an inch, 
                that's also another reason I love coding so much :-). To know me more,  
                <a className="cv" href={cv} target="blank">please find my resume here</a> 
              </p>
            </div>
            <div className="title">
              <h3>What do I do?</h3>
              <p className="separator" />
            </div>
            <div className="desc">
              <h4 className="subtitle">I'm a programmer.</h4>
              <p>
                For the front-end, I usually work with Javascript, either standalone or including
                popular frameworks like React and Angular. For the UI part, I use SCSS,
                CSS and, whenever needed, any of their friends: Bootstrap, MaterialUI, Tailwindcss etc.
              </p>
              <p>
                For the back-end I also work with Javascript (NodeJS, Express etc). 
              </p>
              <p>
                For the back-end infra and database, I work with MongoDB, Firebase etc. 
              </p>
            </div>
            <div className="desc">
              <h4 className="subtitle">Also a designer.</h4>
              <p>
                I feel comfortable working with many Adobe products. Photoshop, Illustrator
                or Xd are some kind of industry standards and I love working with them. I'm
                also familiar with Gimp, Inkscape and Figma.
              </p>
              <p>
                I have been  doing User interfaces, Product designing, and branding.
              </p>
            </div>
          </article>
        </div>
      </section>
    );
  };
  
  
  /***********************
    Project Component
   ***********************/
  
  const Project = props => {
    const tech = {
      sass: 'fab fa-sass',
      css: 'fab fa-css3-alt',
      js: 'fab fa-js-square',
      react: 'fab fa-react',
      vue: 'fab fa-vuejs',
      d3: 'far fa-chart-bar',
      node: 'fab fa-node'
    };
  
    const link = props.link || 'http://';
    const repo = props.repo || 'http://';
  
    return (
      <div className="project">
        <a className="project-link" href={link} target="_blank" rel="noopener noreferrer">
          <img className="project-image" src={props.img} alt={'Screenshot of ' + props.title} />
        </a>
        <div className="project-details">
          <div className="project-tile">
            {/*<p className="icons">
              {props.tech.split(' ').map(t => (
                <i className={tech[t]} key={t} />
              ))}
            </p>*/}
            {props.title}{' '}
          </div>
          {props.children}
          <div className="buttons">
            {repo != 'http://' ? <a href={repo} target="_blank" rel="noopener noreferrer">
              View source <i className="fas fa-external-link-alt" />
            </a> : ''}
            <a href={link} target="_blank" rel="noopener noreferrer">
              Try it Live <i className="fas fa-external-link-alt" />
            </a>
          </div>
        </div>
      </div>
    );
  };
  
  
  
  /***********************
    Projects Component
   ***********************/
  
  const Projects = props => {
    return (
      <section id="projects">
        <div className="projects-container">
          <div className="heading">
            <h3 className="title">My Works</h3>
            <p className="separator" />
            <p className="subtitle">
              Here's a list of few of my works. Few of which, I have done while studying and few of them are more recent.
              You can check out my GitHub account for more projects.
            </p>
          </div>
          <div className="projects-wrapper">
            <Project
              title="A movie library and watching platform"
              img={filmzy}
              tech="js css express node mongodb"
              link="https://filmzy.herokuapp.com/"
              repo="https://github.com/Deblina-create/filmzy"
            >
              <small>
                Built using Node, Express, MongoDB, CSS, and Javascript.
              </small>
              <p>
                This is a full-stack website that I made which lets the users to watch movies. Users can also upload movies upon login.
              </p>
            </Project>
            <Project
              title="Online store for Jam and Jelly."
              img={jam}
              tech="js node express css mongodb cypress"
              link="https://rocky-island-69229.herokuapp.com/"
              repo="https://github.com/Deblina-create/webshop-JAM.git"
            >
              <small>
                Built using Node, Express, MongoDB, JavaScript, Cypress, CSS + Bootstrap.
              </small>
              <p>
                A complete and ready to use webshop. Adequate for small or mid-size online stores.
              </p>
            </Project>
            <Project
              title="A feeding time tracker for animal shelter."
              img={zoo}
              tech="react typescript css"
              link="https://zootopia2021.herokuapp.com/"
              repo="https://github.com/Deblina-create/Zootopia"
            >
              <small>Built using React, Typescript, NodeJS, Azure and CSS + Bootstrap.</small>
              <p>
                A full-stack website to keep a check on the feeding time for an amimal shelter.
              </p>
            </Project>
            <Project
              title="A social netwoking platform for vegetarians & vegans."
              img={bv}
              tech="react firebasebootstrap "
              link="https://beingveg-725d9.web.app/"
            >
              <small>Built using ReactJS, Node, Express, Firestore, CSS + Bootstrap.</small>
              <p>Yet another fullstack website which is used to host events, find nearby restaurants & order food, chat with other users, and lots more. </p>
            </Project>
            <Project
              title="A Landing page"
              img={lp}
              tech="js node css"
              link="https://archangingroom.web.app/"
              repo="https://github.com/Deblina-create/ARChangingRoom"
            >
              <small>Built using  JS + jQuery, CSS.</small>
              <p>
                A landing page for a client that provides virtual fitting room API to business.
              </p>
            </Project>
            
            {/*<Project
              title="URL shortner"
              img={sh}
              tech="ReactJS, Firestore, node, css"
              link="https://shortify-c2708.web.app/"
              repo="https://github.com/Deblina-create/shortify"
            >
              <small>Built using ReactJS, Firestore, Node, Express, CSS.</small>
              <p>
                A microservice which takes a raw URL and makes it a short to make it easy to sent
                it through the Internet.
              </p>
            </Project>*/}
            
          </div>
        </div>
      </section>
    );
  };
  
  
  
  /***********************
    Contact Component
   ***********************/
  
  const Contact = props => {
    return (
      <section id="contact">
        <div className="container">
          <div className="heading-wrapper">
            <div className="heading">
              <p className="title">
                Want to <br />
                contact me?
              </p>
              <p className="separator" />
              <p className="subtitle">
                Please send an email to : {''}
                <span className="mail">
                  deblina4@gmail.com
                  
                </span>
                
              </p>
            </div>
            <SocialLinks />
          </div>
         
        </div>
      </section>
    );
  };
  
  
  
  /***********************
    Footer Component
   ***********************/
  
  const Footer = props => {
    return (
      <footer>
        <div className="wrapper">
          <h3>THANKS FOR VISITING</h3>
          <p>© {new Date().getFullYear()} Deblina M.</p>
          <SocialLinks />
        </div>
      </footer>
    );
  };
  
  
  
  
  /***********************
    Social Links Component
   ***********************/
  
  const SocialLinks = props => {
    return (
      <div className="social">
        <a
          href="https://www.linkedin.com/in/deblina-mondal-bb021711a/"
          target="_blank"
          rel="noopener noreferrer"
          title="Link to author's Twitter profile"
        >
          {' '}
          <i className="fab fa-linkedin-in" />
        </a>
        <a
          id="profile-link"
          href="https://github.com/Deblina-create"
          target="_blank"
          rel="noopener noreferrer"
          title="Link to author's GitHub Profile"
        >
          {' '}
          <i className="fab fa-github" />
        </a>
        
      </div>
    );
  };
  
  
  
  /***********************
    Main Component
   ***********************/
  
  class App extends React.Component {
    state = {
      menuState: false
    };
  
    toggleMenu = () => {
      this.setState(state => ({
        menuState: !state.menuState
          ? 'active'
          : state.menuState === 'deactive'
            ? 'active'
            : 'deactive'
      }));
    };
  
    render() {
      return (
        <React.Fragment>
          <Menu toggleMenu={this.toggleMenu} showMenu={this.state.menuState} />
          <Nav toggleMenu={this.toggleMenu} showMenu={this.state.menuState} />
          <Header />
          <About />
          <Projects />
          <Contact />
          <SocialLinks/>
          <Footer />
        </React.Fragment>
      );
    }
  
    componentDidMount() {
      const navbar = document.querySelector('#navbar');
      const header = document.querySelector('#welcome-section');
      const forest = document.querySelector('.forest');
      const silhouette = document.querySelector('.silhouette');
      let forestInitPos = -300;
  
      window.onscroll = () => {
        let scrollPos = document.documentElement.scrollTop || document.body.scrollTop;
  
        if (scrollPos <= window.innerHeight) {
          silhouette.style.bottom = `${parseInt(scrollPos / 6)}px`;
          forest.style.bottom = `${parseInt(forestInitPos + scrollPos / 6)}px`;
        }
  
        if (scrollPos - 100 <= window.innerHeight)
          header.style.visibility = header.style.visibility === 'hidden' && 'visible';
        else header.style.visibility = 'hidden';
  
        if (scrollPos + 100 >= window.innerHeight) navbar.classList.add('bg-active');
        else navbar.classList.remove('bg-active');
      };
  
      (function navSmoothScrolling() {
        const internalLinks = document.querySelectorAll('a[href^="#"]');
        for (let i in internalLinks) {
          if (internalLinks.hasOwnProperty(i)) {
            internalLinks[i].addEventListener('click', e => {
              e.preventDefault();
              document.querySelector(internalLinks[i].hash).scrollIntoView({
                block: 'start',
                behavior: 'smooth'
              });
            });
          }
        }
      })();
    }
  }

  export default App
  